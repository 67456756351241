<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="32"
    height="27"
    viewBox="0 0 32 27"
  >
    <defs>
      <path
        id="ockfa"
        d="M910.09 1231l-9.66-1.57a.52.52 0 0 1-.43-.51v-22.4c0-.15.07-.3.18-.4a.5.5 0 0 1 .4-.11l9.59 1.55 9.58-1.55h.16l9.66 1.56c.25.04.43.26.43.51v22.4c0 .15-.07.3-.18.4a.5.5 0 0 1-.4.11l-9.59-1.55-9.58 1.55a.5.5 0 0 1-.16 0zm-.25-.12zm10.5-24.36zm-.18-.4z"
      ></path>
      <path
        id="ockfb"
        d="M908.38 1215.34l-.46.5a.66.66 0 0 0 0 .87.53.53 0 0 0 .79 0l.46-.5.46.5c.1.12.25.18.39.18s.28-.06.4-.18a.66.66 0 0 0 0-.87l-.47-.5.72-.8a.66.66 0 0 0 0-.86.52.52 0 0 0-.78 0l-.72.8-.72-.8a.52.52 0 0 0-.79 0 .66.66 0 0 0 0 .87z"
      ></path>
      <path
        id="ockfc"
        d="M919.63 1225.34l-.46.5a.66.66 0 0 0 0 .87.53.53 0 0 0 .79 0l.46-.5.46.5c.1.12.25.18.39.18s.28-.06.4-.18a.66.66 0 0 0 0-.87l-.47-.5.72-.8a.66.66 0 0 0 0-.86.52.52 0 0 0-.78 0l-.72.8-.72-.8a.52.52 0 0 0-.79 0 .66.66 0 0 0 0 .87z"
      ></path>
      <path
        id="ockfd"
        d="M923.38 1212.84l-.46.5a.66.66 0 0 0 0 .87.53.53 0 0 0 .79 0l.46-.5.46.5c.1.12.25.18.39.18s.28-.06.4-.18a.66.66 0 0 0 0-.87l-.47-.5.72-.8a.66.66 0 0 0 0-.86.52.52 0 0 0-.78 0l-.72.8-.72-.8a.52.52 0 0 0-.79 0 .66.66 0 0 0 0 .87z"
      ></path>
    </defs>
    <g>
      <g transform="translate(-899 -1205)">
        <g>
          <g>
            <g>
              <use fill="#fff" xlink:href="#ockfa"></use>
              <use
                fill="#fff"
                fill-opacity="0"
                stroke="#363636"
                stroke-miterlimit="50"
                stroke-width="1.25"
                xlink:href="#ockfa"
              ></use>
            </g>
            <g>
              <use fill="#363636" xlink:href="#ockfb"></use>
            </g>
            <g>
              <use fill="#363636" xlink:href="#ockfc"></use>
            </g>
            <g>
              <use fill="#363636" xlink:href="#ockfd"></use>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TheAddToArtGuideIcon',
};
</script>
