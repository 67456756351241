














import { Component, Prop, Vue } from 'vue-property-decorator';

import AddToArtGuideCnt from '@/components/partials/AddToArtGuideCnt.vue';
import TheCrossIcon from '@/components/icons/TheCrossIcon.vue';

@Component({
  components: {
    TheCrossIcon,
    AddToArtGuideCnt,
  },
})
export default class AddToArtGuide extends Vue {
  @Prop() event!: any;

  created() {
    if (window.innerWidth < 768) {
      this.$store.dispatch('disableScroll');
    }
  }

  handleAddedToGuide() {
    this.$emit('added-to-guide');
    setTimeout(this.close, 700);
  }

  close() {
    this.$emit('close');

    if (window.innerWidth < 768) {
      this.$store.dispatch('enableScroll');
    }
  }
}
