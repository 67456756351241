<template>
  <div class="modal-bg" @click.self="close">
    <div class="add-to-guide-modal">
      <button class="modal-close" @click="close">
        <TheCrossIcon />
      </button>

      <form v-if="!finishCreate" class="add-to-guide-modal__content creation-guide" @submit.prevent="saveNewGuide">
        <div class="creation-guide__input-wrap">
          <div>
            <input
              v-model.trim="title"
              class="creation-guide__input tr-field"
              maxlength="255"
              placeholder="Enter Art Guide title"
              required="required"
              type="text"
              @input="validateInput($event, 'title')"
            />
            <Transition name="fade-down">
              <p v-show="errors.title" class="creation-guide__error">{{ errors.title }}</p>
            </Transition>
          </div>
          <div class="mt-4 mt-md-5">
            <input
              v-model.trim="description"
              class="creation-guide__input tr-field textarea-placeholder"
              maxlength="255"
              placeholder="Enter description"
              type="text"
              @input="validateInput($event, 'description')"
            />
            <Transition name="fade-down">
              <p v-show="errors.description" class="creation-guide__error">{{ errors.description }}</p>
            </Transition>
          </div>
        </div>

        <div class="creation-guide__save">
          <Transition name="fade">
            <button v-if="isFormValid" class="btn" :disabled="isCreating" type="submit">
              <span class="save">Save</span>
            </button>
          </Transition>
        </div>
      </form>

      <div v-else class="add-to-guide-modal__content guide-created">
        <div>
          <h3 class="guide-created__title">Art Guide created!</h3>
          <p class="guide-created__text">Start adding Events, Art Spaces, Exhibitions, Galleries, and more!</p>
        </div>
        <RouterLink class="guide-created__button btn border-btn" :to="{ name: 'destinations' }"
          >Explore destinations</RouterLink
        >
      </div>
    </div>
  </div>
</template>

<script>
import createArtGuideMutation from '@/graphql/artGuide/CreateArtGuide.mutation.gql';

import TheCrossIcon from '@/components/icons/TheCrossIcon.vue';

export default {
  name: 'AddToGuideModal',
  components: { TheCrossIcon },
  data() {
    return {
      title: '',
      description: '',
      errors: {
        title: '',
        description: '',
      },
      isCreating: false,
      finishCreate: false,
      isFormValid: false,
    };
  },
  methods: {
    close() {
      this.title = '';
      this.description = '';
      this.$emit('close');
    },
    validateInput(e, field) {
      const value = e.target.value;

      if (!value) {
        this.isFormValid = false;
        this.errors[field] = 'Please, enter the field.';
        return;
      }
      if (!/^[\w\p{L}\-\s'’".,:;/#&()]*$/iu.test(value)) {
        this.isFormValid = false;
        this.errors[field] = 'Invalid format.';
        return;
      }

      this.isFormValid = true;
      this.errors[field] = '';
    },
    saveNewGuide() {
      if (this.isCreating) {
        return;
      }

      this.isCreating = true;
      this.errors.title = '';
      this.errors.description = '';

      this.$apollo
        .mutate({
          mutation: createArtGuideMutation,
          variables: {
            title: this.title,
            description: this.description,
          },
        })
        .then(({ data }) => {
          this.isCreating = false;
          this.finishCreate = true;
          this.$emit('created', data.createArtGuide);
        })
        .catch((err) => {
          const { graphQLErrors } = err;
          let errorMessage = graphQLErrors[0].message || 'Failed to create your Art Guide.';

          if (graphQLErrors[0].extensions?.validation) {
            this.isFormValid = false;
            errorMessage = 'Please check information provided.';

            const errors = graphQLErrors[0].extensions.validation;
            for (let field in errors) {
              if (this.errors[field] !== undefined) {
                this.errors[field] = errors[field][0];
              }
            }
          }

          this.isCreating = false;
          this.$toast.error(errorMessage);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  z-index: 999;
  background-color: rgba(#000, 0.5);

  @media (max-width: 768px) {
    padding-top: 60px;
  }

  .add-to-guide-modal {
    width: 80%;
    max-width: 840px;
    padding: 60px 30px;
    position: relative;
    background-color: #fff;

    @media (max-width: 768px) {
      height: 70vh;
    }

    &__content {
      max-height: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 768px) {
        justify-content: center;
      }
    }

    .tr-field {
      border: 0;
      background: transparent;
      text-align: center;
      color: #363636;
      font-size: 40px;
      font-weight: 800;
      text-transform: uppercase;
      letter-spacing: 5.71px;

      @media (max-width: 768px) {
        font-size: 18px;
      }

      &::placeholder {
        font-weight: 300;
        text-transform: uppercase;
        color: #afafaf;
        font-size: 20px;

        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
