<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="30"
    height="30"
    viewBox="0 0 30 30"
  >
    <defs>
      <path
        id="aj9ma"
        d="M653.365 340.284l-7.64-1.275a.613.613 0 0 0-.362.049l-7.114 3.25-5.959-2.722a.621.621 0 0 0-.48-.016l-7.405 2.786a.625.625 0 0 0-.405.585v18.932a.628.628 0 0 0 .625.625.634.634 0 0 0 .22-.04l7.164-2.698 5.697 2.55a.624.624 0 1 0 .512-1.14l-5.933-2.655a.609.609 0 0 0-.475-.015l-6.56 2.47v-17.596l6.758-2.544 5.98 2.733a.62.62 0 0 0 .52 0l7.2-3.29 6.93 1.156v17.65l-1.762-.329a.623.623 0 1 0-.23 1.228l2.503.466a.627.627 0 0 0 .74-.614V340.9a.627.627 0 0 0-.524-.616z"
      ></path>
      <path id="aj9mb" d="M631.509 343.915v12.012a.625.625 0 0 0 1.25 0v-12.012a.625.625 0 0 0-1.25 0z"></path>
      <path id="aj9mc" d="M638.976 350.824v-4.583a.625.625 0 0 0-1.25 0v4.583a.625.625 0 0 0 1.25 0z"></path>
      <path id="aj9md" d="M646.484 349.134v-6.071a.625.625 0 0 0-1.25 0v6.07a.625.625 0 0 0 1.25 0z"></path>
      <path
        id="aj9me"
        d="M643.984 367.07c-1.648-2.595-4.614-7.673-4.614-9.645 0-2.529 2.07-4.585 4.614-4.585 2.543 0 4.613 2.058 4.613 4.585.002 1.972-2.965 7.05-4.613 9.645zm0-15.48c-3.234 0-5.864 2.619-5.864 5.838 0 2.982 4.796 10.32 5.343 11.147a.625.625 0 0 0 1.042 0c.547-.827 5.343-8.165 5.343-11.147 0-3.219-2.63-5.838-5.864-5.838z"
      ></path>
      <path
        id="aj9mf"
        d="M643.981 357.707a.668.668 0 0 1-.671-.663c0-.365.301-.663.671-.663.37 0 .67.298.67.663 0 .365-.3.664-.67.664zm0-2.577a1.92 1.92 0 0 0-1.921 1.913c0 1.056.861 1.913 1.921 1.913a1.92 1.92 0 0 0 1.92-1.914 1.919 1.919 0 0 0-1.92-1.912z"
      ></path>
    </defs>
    <g>
      <g transform="translate(-624 -339)">
        <g>
          <use xlink:href="#aj9ma"></use>
        </g>
        <g>
          <use xlink:href="#aj9mb"></use>
        </g>
        <g>
          <use xlink:href="#aj9mc"></use>
        </g>
        <g>
          <use xlink:href="#aj9md"></use>
        </g>
        <g>
          <use xlink:href="#aj9me"></use>
        </g>
        <g>
          <use xlink:href="#aj9mf"></use>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TheMapWithMarkerIcon',
};
</script>
