














import { Component, Prop, Vue } from 'vue-property-decorator';

import AddToArtGuide from '@/components/partials/AddToArtGuide.vue';
import TheAddToArtGuideIcon from '@/components/icons/TheAddToArtGuideIcon.vue';

@Component({
  components: {
    AddToArtGuide,
    TheAddToArtGuideIcon,
  },
})
export default class AddToGuideAction extends Vue {
  @Prop() event!: any;
  @Prop({ default: true, type: Boolean }) showTitle: boolean;

  actionTitle = 'Add to Art Guide';
  showAddToGuideModal = false;

  addToGuide() {
    this.showAddToGuideModal = true;
  }

  addedToGuide() {
    //
  }

  close() {
    this.showAddToGuideModal = false;
  }
}
